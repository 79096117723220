import useFetch from '../hooks/useFetch';
import ImageSlider from '../components/ImageSlider';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from "remark-breaks";

const strapiURL: string = "https://admin.smkcars.uk";
const defaultImageURL = strapiURL + "/uploads/car_placeholder_1e1c7fa873.jpg";

interface CarAttributes {
    title: string;
    state: string;
    price?: number;
    year: number;
    mileage: number;
    fuel: string;
    power: number;
    engine_size: string;
    doors: number;
    seats?: number;
    gearbox: string;
    drive: string;
    body: string;
    color: string;
    country: string;
    first_registration: string;
    vin: string;
    gallery: {
        data: {
            attributes: {
                url: string;
            };
        }[];
    };
    description: string;
}

interface Car {
    id: number;
    attributes: CarAttributes;
}

interface ImageAttributes {
    alternativeText: null;
    caption: null;
    createdAt: string;
    ext: string;
    formats: any;
    hash: string;
    height: number;
    mime: string;
    name: string;
    previewUrl: null;
    provider: string;
    provider_metadata: null;
    size: number;
    updatedAt: string;
    url: string;
    width: number;
}

interface Image {
    attributes: ImageAttributes;
    id: number;
}

export default function CarPage() {
    const { id } = useParams();

    let pagination: number = Number(id) - 20;
    if (pagination < 0) pagination = 0;
    const apiURL = 'https://admin.smkcars.uk/api/cars?sort=date&pagination[start]=' + pagination + '&pagination[limit]=100&populate=* ';

    const { loading, error, data } = useFetch(apiURL);
    let imagesURLs: string[] = [];
    let idCar: number = Number(id);

    function isThatCar(fetchedCar: Car[]) {
        return fetchedCar[1].id === idCar;
    }

    if (loading) return <Loader />
    if (error) return <p>Error!!!</p>

    let foundCar: any = data.find(isThatCar);

    if (foundCar[1]?.attributes?.gallery?.data?.length > 0) {
        foundCar[1].attributes.gallery.data.map((image: Image) => {

            const largeUrl = image?.attributes?.formats?.large?.url?.trim();
            const defaultUrl = image?.attributes?.url;

            if (largeUrl) {
                const fullUrl = strapiURL + largeUrl.trim();
                imagesURLs.push(fullUrl);
            } else {
                imagesURLs.push(defaultUrl);
                console.warn("Large image not available, defaulting to original image.")
            }
        });
    } else {
        imagesURLs.push(defaultImageURL);
        console.warn("No images found, setting default image.")
    }

    return (
        <div className='CarPage relative pb-64'>
            <h1 className='py-4 px-2 font-bebasFont text-2xl text-center
            sm:py-6 sm:text-3xl'>
                {foundCar[1].attributes.title + ' '}
                <div className='CarPage__price__area font-medium text-red-600 sm:inline-block' style={
                    foundCar[1].attributes.state === 'sold' ||
                        foundCar[1].attributes.state === 'soon' ? { display: 'none' } : {}
                }>
                    <span className='CarPage__price__area__dash px-1'>{foundCar[1].attributes.price ? ` - ` : ' '}</span>
                    <span className='CarPage__price__area__number'>
                        {foundCar[1].attributes.state === 'reserved' ?
                            ' - Reserved' :
                            '£' + foundCar[1].attributes.price}
                    </span>
                </div>
            </h1>

            {/* Mini slider */}
            <ImageSlider id="ImageSlider" slides={imagesURLs} />

            <table className='relative top-16 w-90vw max-w-lg mx-auto bg-gray-100 border border-black shadow-car-page-shadow text-[15px] font-medium
            lg:inline-block lg:top-0 lg:left-2 lg:max-w-none lg:w-[360px]
            xl:left-4
            '>
                <tbody className='block width-full'>
                    <tr className="CarPage__table__body__row border-0">
                        <td className="CarPage__table__body__row__cell-first">Year:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.year}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Mileage:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.mileage}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Fuel Type:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.fuel}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Engine Power:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.power} BHP</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Engine Capacity:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.engine_size}cc</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Number of doors:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.doors}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Number of seats:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.seats}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Gearbox:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.gearbox}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Drivetrain:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.drive}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Body Type:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.body}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Colour:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.color}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Country:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.country}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Registration:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.first_registration}</td>
                    </tr>
                    <tr className="CarPage__table__body__row">
                        <td className="CarPage__table__body__row__cell-first">Owners:</td>
                        <td className="CarPage__table__body__row__cell-second">{foundCar[1].attributes.owners}</td>
                    </tr>
                </tbody>
            </table>

            <div className='CarPage__description relative top-32 w-90vw max-w-[1080px] mx-auto bg-gray-100 shadow-car-page-shadow'>
                <div className="pt-3 pb-7 font-medium text-3xl text-center underline underline-offset-[12px]">Description</div>
                <ReactMarkdown className='px-2' remarkPlugins={[remarkBreaks]} children={foundCar[1].attributes.description} />
            </div>
        </div>
    )
}