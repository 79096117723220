import Car from '../components/Car';
import Loader from '../components/Loader';

const strapiURL: string = "https://admin.smkcars.uk";
const defaultImageURL = strapiURL + "/uploads/car_placeholder_1e1c7fa873.jpg";

interface CarAttributes {
  state: string;
  title: string;
  mileage: number;
  year: number;
  fuel: string;
  power: number;
  gallery: GalleryItem;
  imageSource: string; // Ensure this is a string
}

interface GalleryItem {
  data: {
    attributes: {
      formats: {
        large: {
          url: string;
        };
        medium: {
          url: string;
        };
        small: {
          url: string;
        };
        thumbnail: {
          url: string;
        };
      };
    };
    id: number;
  }[];
}

interface CarData {
  id: string;
  attributes: CarAttributes;
}

interface OfferProps {
  arrayToDisplay: CarData[][];
  isLoading: boolean;
}

export default function Offer(props: OfferProps) {
  const { arrayToDisplay, isLoading } = props;

  return (
    <div id="Offer">
      <div className="cards-area">
        {isLoading ? (
          <Loader />
        ) : (
          arrayToDisplay.map((car: CarData[], index: number) => {
            const galleryData = car[1].attributes.gallery?.data;

            // Check if there's at least one image in the gallery and use a default image if not
            const imageSource = galleryData && galleryData.length > 0
              ? strapiURL + galleryData[0].attributes.formats.small.url
              : defaultImageURL; // Use default if no valid image

            return (
              <Car
                key={"Car no " + index}
                id={car[1].id}
                state={car[1].attributes.state}
                title={car[1].attributes.title}
                mileage={car[1].attributes.mileage}
                year={car[1].attributes.year}
                fuel={car[1].attributes.fuel}
                power={car[1].attributes.power}
                imageSource={imageSource} // Now always has a valid image source
              />
            );
          })
        )}
      </div>
    </div>
  );
}

