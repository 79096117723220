import coins from '../assets/financing/coins.webp';

export default function Financing() {
    return (
        <div id='Financing' className="w-[90vw] mx-auto pb-[80px] 
        lg:w-[800px] lg:pb-12">
            <h1 className="page-title py-5">
                FINANCING
            </h1>
            <div className="box-border w-full items-center p-5 pt-0 flex flex-col-reverse 
            lg:flex-row">
                <img src={coins} alt='Assistance in obtaining car leasing' className="w-4/5 lg:w-[400px]"/>
                <ul className="w-4/5 list-disc pl-8 lg:w-[400px]">
                    <li className="financing__options">
                        We can organise all forms of financing on very favourable terms.
                    </li>
                    <li className="financing__options">
                        Financial products for businesses and individuals (operating leasing, financial leasing, leasing loans, or credit).
                    </li>
                    <li className="financing__options">
                        We work with the largest banks and leasing companies, providing a wide range of options and comprehensive support.
                    </li>
                </ul>
            </div>
        </div>
    )
}
