export default function About() {
    return (
        <div id="About" className="mx-auto w-[90vw] pb-[80px]
        lg:w-[1000px] lg:pb-[50px]">
            <h1 className="page-title pt-5">About Us</h1>
            <div className="mb-5">
                <h2 className="about__subtitle">History</h2>
                <p>
                    <span className="font-bold">SMK Cars</span> is a family-owned business specialising in the sale of luxury premium brand cars with a tradition dating back almost thirty years.
                </p>
                <p>
                    It was born out of a passion for cars, where experience and knowledge about automobiles were passed down from generation to generation.
                </p>
            </div>

            <div className="mb-[20px]">
                <h2 className="about__subtitle">Purpose</h2>
                <p>
                    Our primary goal is to approach each customer individually, sincerely, and honestly to ensure they consciously choose the best car for themselves.
                </p>
                <p>
                    Most of our customers, after a successful first transaction, return to us for another secure purchase and recommend our services further.
                </p>
            </div>

            <div className="mb-[20px]">
                <h2 className="about__subtitle">Foundation of Business</h2>
                <p>
                    All the cars are owned by us. The decision to buy is always preceded by thorough vehicle inspection and meeting strict selection criteria.
                </p>
                <p>
                    We only purchase vehicles with fully documented usage and service history, are accident-free, and often have factory warranties.
                </p>
            </div>

            <div className="mb-[20px]">
                <h2 className="about__subtitle">Comprehensive Approach to Car Change</h2>
                <p>
                    We take a comprehensive approach to the issue of changing cars by a potential customer:
                </p>
                <ul className="list-disc pl-[25px] m-[3px]">
                    <li>We provide advice on selecting the brand and model that meets your expectations</li>
                    <li>We search for vehicles on your behalf based on specific requirements</li>
                    <li>If you have a vehicle in very good condition, we can buy it or take it as part-exchange for a car from our offer</li>
                    <li>We organise very favourable financing for businesses as well as for individuals.</li>
                </ul>
            </div>
            
            <h3 className="font-bebasFont text-center text-3xl font-medium pt-2 pb-7 mx-auto my-0">
                We invite you to contact us via the form, by email, or by phone!
            </h3>
        </div>
    );
}
