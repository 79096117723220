import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For handling navigation
import phoneIcon from '../assets/contact/phoneIcon.png';
import emailIcon from '../assets/contact/email.png';

export default function Contact() {
    const [formData, setFormData] = useState({ name: '', phone: '', email: '', message: '' });
    const navigate = useNavigate(); // For navigating to different routes

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name.toLowerCase()]: value })); // Update form data on change
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            const response = await fetch('https://esi.osbornetech.support/admin/email-relay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Correct content type for form data
                },
                body: new URLSearchParams(formData as Record<string, string>).toString(), // Convert form data to URL-encoded format
            });

            if (response.ok) {
                navigate('/thanks'); // Redirect to the 'thanks' page upon successful submission
            } else {
                const errorText = await response.text();
                console.error('Form submission failed:', errorText); // Handle errors
            }
        } catch (error) {
            console.error('An error occurred:', error); // Handle fetch errors
        }
    };

    return (
        <div id="Contact" className="flex flex-col-reverse w-full pb-16 font-bebasFont
        lg:flex-row lg:mx-auto lg:max-w-5xl">
            <span className='w-11/12 mx-auto text-lg
            sm:w-[36rem]
            lg:w-6/12 lg:mr-5
            xl:mr-10'>
                <h3 className="contact__container__title">Contact Form</h3>

                <form onSubmit={handleSubmit}>
                    <input
                        className='contact__container__form__input contact__container__form__input-contour'
                        type="text"
                        name="name"
                        placeholder='Name'
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />
                    
                    <input
                        className='contact__container__form__input contact__container__form__input-contour'
                        type="text"
                        name="phone"
                        placeholder='Phone Number'
                        value={formData.phone}
                        onChange={handleChange}
                    />

                    <input
                        className='contact__container__form__input contact__container__form__input-contour'
                        type="email"
                        name="email"
                        placeholder='Email Address'
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />

                    {/* Text area for the message */}
                    <textarea
                        className='contact__container__form__input contact__container__form__input-contour h-48'
                        name="message"
                        placeholder='Message'
                        required
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                    />

                    {/* Hidden field for the security key */}
                    <input
                        type="hidden"
                        name="security_key"
                        value="ICtGazyLjlcG40RbCSG3s1MSBsOvFJT5" // The security key for the server-side check
                    />

                    {/* Submit button */}
                    <input
                        className="contact__container__form__input contact__container__form__input-contour border-2 my-1 mx-auto pt-0.5 w-16 tracking-wide border-black rounded-md block duration-1000 transition-colors cursor-pointer hover:bg-black hover:text-white"
                        type="submit"
                        value="Submit"
                    />
                </form>
            </span>

            {/* Contact Info */}
            <span className='w-11/12 mx-auto text-lg
            sm:w-[36rem]
            lg:w-5/12 lg:ml-0'>
                <h3 className='contact__container__title'>Contact Info</h3>
                
                <div className="flex text-center">
                    <img className="relative top-1 h-4 pr-2" src={phoneIcon} alt="" />
                    <span>Tel: 07961 834738</span>
                </div>
                <div className="flex text-center">
                    <img className="relative top-1 h-4 pr-2" src={emailIcon} alt="" />
                    <span>Email: admin@smkcars.uk</span>
                </div>

                {/* Map */}
                <iframe
                    className="block my-2 mx-auto h-72 w-full border border-black rounded-md lg:h-64"
                    title='Map'
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d148.3602041186222!2d-1.298543410175029!3d53.49055957492854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1714520270445!5m2!1sen!2suk"
                ></iframe>
            </span>
        </div>
    );
}
