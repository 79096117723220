import Car from '../components/Car';
import Loader from '../components/Loader';
import PagesToggler from '../components/PagesToggler';

const strapiURL: string = "https://admin.smkcars.uk";
const defaultImageURL = strapiURL + "/uploads/car_placeholder_1e1c7fa873.jpg";

interface CarAttributes {
    state: string;
    title: string;
    mileage: number;
    year: number;
    fuel: string;
    power: number;
    gallery: GalleryItem;
    imageSource: string; // Ensure this is a string
}

interface GalleryItem {
    data: {
        attributes: {
            formats: {
                large: {
                    url: string;
                };
                medium: {
                    url: string;
                };
                small: {
                    url: string;
                };
                thumbnail: {
                    url: string;
                };
            };
        };
        id: number;
    }[];
}

interface CarData {
    id: string;
    attributes: CarAttributes;
}


interface SoldProps {
    arrayToDisplay: CarData[][];
    isLoading: boolean;
    pagesQuantity: number;
}

export default function Sold(props: SoldProps) {
  const { arrayToDisplay, isLoading, pagesQuantity } = props;

  return (
    <div id="Sold">
      <h1 className="page-title pb-8">Sold</h1>
      <div className="cards-area cards-area--sold">
        {isLoading ? (
          <Loader />
        ) : (
          arrayToDisplay.map((car: CarData[], index: number) => {
            const galleryData = car[1].attributes.gallery.data;

            // Check if gallery data is valid and has at least one image
            const imageSource =
              galleryData && galleryData.length > 0
                ? strapiURL + galleryData[0].attributes.formats.small.url
                : defaultImageURL; // Use default image if no valid images

            return (
              <Car
                key={"Car no " + index}
                id={car[1].id}
                state={car[1].attributes.state}
                title={car[1].attributes.title}
                mileage={car[1].attributes.mileage}
                year={car[1].attributes.year}
                fuel={car[1].attributes.fuel}
                power={car[1].attributes.power}
                imageSource={imageSource} // Pass default image if necessary
              />
            );
          })
        )}
      </div>

      <PagesToggler pagesQuantity={pagesQuantity} />
    </div>
  );
}